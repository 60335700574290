<template>
  <div class="parent flex align-center justify-center">
    <div class="container-upload">
      <r-icon
        icon="warning"
        fill="fargo"
        size="56"
      />
      <h1 class="ricotta mb-4 mt-7">
        {{ title || "Удалить объект?" }}
      </h1>
      <div class="mb-8 font">
        {{ subtitle || "Это действие невозможно будет отменить" }}
      </div>
      <div class="mt-2 buttons">
        <r-button
          :disabled="isLoading"
          class="flex-1"
          color="secondary"
          @click.native.stop.prevent="$rir.modal.close"
          :title="buttons[0] || 'Не удалять' "
          width="wide"
        />
        <r-button
          :disabled="isLoading"
          class="flex-1"
          @click.native.prevent.stop="Delete"
          :title=" buttons[1] || 'Да, удалить'"
          width="wide"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/Api';

export default {
  name: 'DeleteModal',
  props: {
    id: [Number, String],
    actionPostfix: String,
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => ['Не удалять', 'Да, удалить']
    },
    saveFunction: {
      type: String
    },
    afterSaveFunction: {
      type: String
    },
    reloadTo: {
      type: String,
      default: 'main'
    }
  },
  data() {
    return {
      upload: false,
      isLoading: false
    };
  },
  methods: {
    async Delete() {
      this.isLoading = true;
      if (this.$props.saveFunction) {
        this.$store.dispatch(this.$props.saveFunction, this.$props.id).then(() => {
          /* if (this.reloadTo) {
            this.$router.push({ name: this.reloadTo });
          } else {
            this.$attrs.closeModal();
            this.$store.dispatch(this.$props.afterSaveFunction);
          } */
          const r = this.$router.resolve({
            name: this.reloadTo || 'main'
          });
          window.location.assign(r.href);
        });
      } else {
        await new Api()
          .operateIncident({
            id: this.$props.id,
            action: 'delete'
          })
          .then(() => {
            const r = this.$router.resolve({
              name: this.reloadTo || 'main'
            });
            window.location.assign(r.href);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.parent {
  width: 100%;
  height: 100%;
}

.loading {
  clip-path: inset(0 1.3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -.8ch 0 0)
  }
}

.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media (max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
</style>

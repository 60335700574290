<template>
  <div>
    <div
      class="search_input"
      v-click-outside="hideResults"
    >
      <r-input
        :label="label"
        v-model="searchText"
        @focus="showResults"
      >
        <r-icon
          :size="16"
          slot="before"
          icon="search"
          fill="titanic"
          class="opacity-48"
        />
      </r-input>
      <div
        class="scroll_container"
        v-show="searchText && isShowResults"
      >
        <div
          class="scroll_container__item flex align-center"
          v-for="(object, key) of dataItems"
          :key="object + key"
        >
          <r-checkbox
            class="mr-2"
            v-model="choicest"
            :val="{
              id: object.id,
              address: object.address,
              lat: object.lat,
              lng: object.lon
            }"
            return-object
            @input="$emit('changed', choicest)"
          />
          <div
            class="sulguni opacity-72"
            :inner-html.prop="object.address | highlights(searchText)"
          />
        </div>
        <p
          class="taleggio mt-3 mb-3"
          style="text-align: center"
          v-if="dataItems.length < 1"
        >
          Ничего не найдено
        </p>
      </div>
    </div>
    <div class="selected_list ml-4 mt-6">
      <div
        class="flex align-center mb-4 feta selected_list__item"
        :title="obj"
        v-for="(obj, index) of choicest"
        :key="index"
      >
        <r-icon
          :size="16"
          icon="geopoint"
          fill="rocky"
          class="mr-2"
        />
        <span class="opacity-72 mr-4">{{ obj.address }}</span>
        <div
          class="delete_button opacity-72"
          @click="onDelete(obj)"
        >
          <r-icon
            :size="16"
            icon="delete"
            fill="fargo"
          />
        </div>
      </div>
    </div>
    <message ref="message" />
  </div>
</template>

<script>
import _ from 'lodash';
import { wordMatch } from '../helpers/utils';
import Api from '../api/Api';
import Message from './Message';

export default {
  name: 'DropdownSearch',
  components: { Message },

  filters: {
    highlights(stringToSearch, searchTerm) {
      if (searchTerm === '') return stringToSearch;
      const iQuery = new RegExp(searchTerm, 'ig');
      return stringToSearch.toString().replace(iQuery, (matchedText, a, b) => `<span style='color: #3D75E4' class='highlight'>${matchedText}</span>`);
    }
  },
  directives: {
    clickOutside: {
      bind(el, binding, vnode) {
        const vm = vnode.context;
        const callback = binding.value;

        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            return callback.call(vm, event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },
  props: {
    label: {
      type: String,
      default: 'Поиск'
    },
    value: {
      type: Object,
      default: null
    },
    residentAddressees: {
      type: Array
    }
  },
  data() {
    return {
      searchText: '',
      dataItems: [],
      choicest: [],
      isShowResults: false
    };
  },
  computed: {
    filteredObjects() {
      if (this.searchText && this.searchText.length > 0) {
        return this.items.filter(result => wordMatch(result.address.toLowerCase(), this.searchText.toLowerCase()) <= 0.95);
      }
      return [];
    }
  },
  watch: {
    searchText: _.debounce(function (newVal) {
      this.changeSearch(newVal);
    }, 500)
  },
  mounted() {
    this.choicest = this.$props.residentAddressees;
  },
  methods: {
    async changeSearch(searchText) {
      // console.log(this.$cityName)
      await new Api().getHousesList(searchText, window.token).then(res => {
        if (res.error) {
          this.$refs.message.showMessage(res.error, 'error');
        } else {
          this.dataItems = res.buildings;
        }
      });
      /* window.ymaps.suggest(`Нижегородская область, ${this.searchText}`).then(items => {
        this.dataItems = items;
      }); */
    },
    onDelete(id) {
      this.choicest = this.choicest.filter(item => item !== id);
      this.$emit('changed', this.choicest);
    },
    hideResults() {
      this.isShowResults = false;
    },
    showResults() {
      this.isShowResults = true;
    }
  }
};
</script>

<style scoped lang="scss">
.search_input {
  position: relative;

  .scroll_container {
    z-index: 1;
    position: absolute;
    top: 44px;
    left: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 8px 32px rgba(4, 21, 62, 0.16);
    border-radius: 8px;
    padding: 26px 20px;
    overflow-y: auto;
    max-height: 245px;
    scrollbar-color: rgba(#04153E, 0.08) transparent;

    &::-webkit-scrollbar-track {
      width: 4px;
      background: #FFFFFF;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background: #e4edfb;
      border-radius: 2px;
      opacity: 0.72;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: #e4edfb;
      border-radius: 2px;
      opacity: 0.72;
    }
    &__item {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}

.selected_list__item {
  position: relative;

  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .delete_button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      opacity: 1 !important;
    }
  }
}
</style>

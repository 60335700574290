<template>
  <div>
    <div
      class="contact-item flex align-center"
      v-for="(item, index) of $store.state.categories.categories"
      :key="index"
    >
      <r-icon
        class="mr-2"
        :icon="icon"
        :size="iconSize"
        v-if="!!icon"
      />
      <div class="flex flex-column mr-6">
        <span class="contact-item__text feta">{{ item.title }}</span>
        <span
          v-if="item.subValue"
          class="mozzarella opacity-48"
        >{{ item.subValue }}</span>
      </div>
      <div
        class="contact-item__btn flex align-center mr-4"
        @click="edit({ id: item.id, text: item.title })"
      >
        <r-icon
          icon="edit"
          fill="rocky"
          :size="iconSize"
        />
      </div>
      <div
        class="contact-item__btn flex align-center"
        @click="remove(item.id)"
      >
        <r-icon
          icon="delete"
          fill="fargo"
          :size="iconSize"
        />
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import DeleteModal from './DeleteModal';
import ModalEditSettings from './ModalEditSettings';

export default {
  name: 'SelectedList',
  props: {
    icon: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: '16'
    },
    value: {
      type: Array,
      default: () => []
    },
    textValue: {
      type: [String, null],
      default: null
    },
    subValue: {
      type: String
    }
  },
  methods: {
    async remove(id) {
      await this.$refs.modal.openModal(DeleteModal, {
        id,
        title: 'Удалить категорию?',
        subtitle:
          'Категория «Капитальный ремонт» будет удалена из всех проектов, взамен будет присвоена категория «Без категории»',
        saveFunction: 'deleteCategory',
        afterSaveFunction: 'getCategories'
      });
    },
    async edit(data) {
      await this.$refs.modal.openModal(ModalEditSettings, {
        id: data.id,
        categoryText: data.text,
        saveFunction: this.saveEdit
      });
    },
    async saveEdit(id, title) {
      console.log(id, title);
      // запрос на сохранение категории
      await this.$store
        .dispatch('editCategory', {
          id,
          title
        })
        .then(() => {
          this.$store.dispatch('getCategories');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-item {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &__text {
    opacity: 0.72;
  }

  &__btn {
    cursor: pointer;
    opacity: 0;
    transition: .2s;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    .contact-item__btn {
      opacity: .72;
    }
  }
}
</style>

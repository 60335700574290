import Api from '@/api/Api';

const api = new Api();
export default {
  state: () => ({
    categories: [],
    isLoading: true
  }),
  mutations: {
    setCategories(state, res) {
      state.categories = res;
    },
    setLoading(state, bool) {
      state.isLoading = bool;
    }
  },
  actions: {
    async getCategories(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getIncidentTypes();
      ctx.commit('setCategories', res.all || []);
      ctx.commit('setLoading', false);
      return res;
    },
    async addCategory(ctx, title) {
      return await api.operateIncidentType({
        id: -1,
        action: 'update',
        item: {
          id: -1,
          title
        }
      });
    },
    async editCategory(ctx, payload) {
      return await api.operateIncidentType({
        id: payload.id,
        action: 'update',
        item: {
          id: payload.id,
          title: payload.title
        }
      });
    },
    async deleteCategory(ctx, id) {
      return await api.operateIncidentType({
        id,
        action: 'delete',
        item: {
          id
        }
      });
    }
  },
  getters: {
    getCategoriesForSelect(state) {
      return state?.categories?.map(item => ({
        id: item.id,
        title: item.title
      })) || [];
    }
  }
};

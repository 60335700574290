<template>
  <div class="container-600">
    <p class="camembert mb-6">
      Категория
    </p>
    <div class="field_wrapper mb-8 flex-1">
      <r-input
        class="flex-1"
        label="Категория"
        v-model.trim="$v.category.$model"
      />
      <div
        class="error mozzarella color-fargo bottom"
        v-if="$v.category.$error && !$v.category.required"
      >
        Поле обязательно для заполнения
      </div>
      <div
        class="error mozzarella color-fargo bottom"
        v-if="!$v.category.maxLength"
      >
        Максимальное количество символов — 40
      </div>
    </div>
    <r-button
      @click="save"
      :disabled="!category"
      :class="{ btn_error: $v.category.$error }"
      title="Сохранить"
      width="wide"
    />
  </div>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators';

export default {
  name: 'ModalEditSettings',
  props: {
    id: {
      required: true
    },
    categoryText: {
      type: String
    },
    saveFunction: {
      required: true,
      type: Function
    }
  },
  validations: {
    category: {
      required,
      maxLength: maxLength(40)
    }
  },
  data() {
    return {
      category: ''
    };
  },
  mounted() {
    this.category = this.$props.categoryText;
  },
  methods: {
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveFunction(this.$props.id, this.category);
        this.$attrs.closeModal();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container-600 {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.field_wrapper {
  position: relative;
  .btn_error {
    background: rgba(#e14761, 0.32);
    pointer-events: none;
  }

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}
</style>

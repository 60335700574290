<template>
  <div>
    <router-link :to="`details/${card.id}`">
      <div class="card">
        <div>
          <div class="flex align-center mb-1">
            <p class="sulguni mr-2">
              {{ card.formName || card.details.patternName }}
            </p>
            <r-tooltip
              activator-hover
              color="amelie"
              :title="card.details.description || ''"
              position="top-center"
              max-width="400px"
              :is-arrow="true"
            >
              <template #activator>
                <r-icon
                  v-if="card.details.description"
                  icon="help"
                  size="16"
                  fill="titanic"
                  class="pointer opacity-48"
                />
              </template>
            </r-tooltip>
          </div>
          <p class="mozzarella opacity-72 mb-3">
            {{ card.firm || "—" }}
          </p>
          <div class="flex align-center">
            <div class="flex align-center briscola mr-5">
              <r-icon
                icon="calendar"
                fill="rocky"
                class="mr-1"
                size="16"
              />

              {{
                (/^-?\d+$/.test(card.details.dateFrom) ?
                  getFormattedDate(card.details.dateFrom)
                  : card.details.dateFrom) || "Не указано"
              }} –
              {{
                (/^-?\d+$/.test(card.details.dateTo) ?
                  getFormattedDate(card.details.dateTo)
                  : card.details.dateTo) || "Не указано"
              }}
            </div>
            <div class="flex align-center briscola mr-5">
              <r-icon
                icon="send"
                fill="rocky"
                class="mr-1"
                size="16"
              />
              {{ card.details.recipients ? card.details.recipients.length || "0" : "0" }}
            </div>
            <div
              class="flex align-center briscola"
              :class="{ 'color-fargo': !card.details.categoryData }"
            >
              <r-icon
                icon="icon-view"
                :fill="card.details.categoryData ? 'rocky' : 'fargo'"
                class="mr-1"
                size="16"
              />
              {{ card.details.categoryData ? card.details.categoryData.title : "Без категории" }}
            </div>
          </div>
        </div>
        <div
          class="flex align-center ml-auto"
          v-if="this.$store.getters.userIsRoot"
        >
          <additional-menu>
            <ul class="card__menu">
              <li
                class="sulguni flex pointer opacity-72 mb-6"
                @click.stop.prevent="copyItem"
              >
                <r-icon
                  class="mr-3"
                  icon="copy"
                  fill="rocky "
                  size="16"
                />
                Дублировать
              </li>
              <li
                class="sulguni flex pointer opacity-72"
                @click.stop.prevent="onDelete"
              >
                <r-icon
                  class="mr-3"
                  icon="delete"
                  fill="fargo"
                  size="16"
                />
                Удалить
              </li>
            </ul>
          </additional-menu>
        </div>
      </div>
    </router-link>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import AdditionalMenu from './AdditionalMenu.vue';
import DeleteModal from './DeleteModal';
import { formattedDate } from '../helpers/utils';

export default {
  name: 'CardItem',
  components: { AdditionalMenu },
  props: {
    card: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowPopover: false
    };
  },
  methods: {
    async onDelete() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.$props.card.id,
        title: 'Удалить оперативное оповещение?'
      });
    },
    copyItem() {
      this.$router.push({ path: `details/${this.card.id}`, query: { copy: true } });
    },
    getFormattedDate(date) {
      return formattedDate(Number(date));
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px;
  border-radius: 16px;

  &:hover {
    box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
  }

  .pointer {
    cursor: pointer;
  }
}

.popover {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.popover__content {
  overflow: visible !important;
  position: absolute;
  right: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  border-radius: 12px;
  background-color: #fff;
  -webkit-filter: drop-shadow(0px 1px 2px #ccc);
  filter: drop-shadow(0px 1px 2px #ccc);
  z-index: 100;
}

::v-deep .rir-tooltip {
  display: flex;
  align-items: center;
}

::v-deep .rir-tooltip__wrapper {
  z-index: 1;
  top: unset !important;
  bottom: calc(100% + 6px);
  max-width: 400px;
}

::v-deep .card__menu {
  li {
    &:hover {
      opacity: 1;
    }
  }
}
</style>

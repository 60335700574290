import Api from '@/api/Api';

const api = new Api();
export default {
  state: () => ({
    alerts: [],
    isLoading: true
  }),
  mutations: {
    setAlerts(state, res) {
     // state.alerts = res.filter(item => !!item.details);
      const details = {
        pattern: {
          id: 0,
          title: 'ГО ЧС'
        },
        patternName: 'ГО ЧС',
        formName: 'ГО ЧС',
        category: null,
        firm: null,
        fio: null,
        job: null,
        phones: [],
        dateFrom: null,
        dateTo: null,
        timeFrom: null,
        timeTo: null,
        description: null,
        coordinates: null,
        work: null,
        choicestEmails: [],
        choicestPhones: [],
        residentAddressees: [],
        addresses: [],
        peopleTotal: null,
        recipients: []
      }
      state.alerts = res.map(el => {
        const camelCaseObj = {};
        for (const elem in el) {
          camelCaseObj[elem.split('')[0].toLowerCase() + elem.slice(1)] = el[elem];
        }
        return {
          ...el,
          details: {
            ...details,
            ...el.details,
            camelCaseObj,
            dateTo: el?.details?.dateTo ? el?.details?.dateTo : camelCaseObj.dateTo,
            dateFrom: el?.details?.dateFrom ? el?.details?.dateFrom : camelCaseObj.dateFrom,
            description: el?.details?.description ? el?.details?.description : camelCaseObj.description,
            categoryData: el?.details?.categoryData ? el?.details?.categoryData : camelCaseObj.categoryData,
            recipients: el?.details?.recipients ? el?.details?.recipients : camelCaseObj.recipients,
          }
        };
      });
    },
    setLoading(state, bool) {
      state.isLoading = bool;
    }
  },
  actions: {
    async getAlerts(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getIncidents();
      ctx.commit('setAlerts', res.all);
      ctx.commit('setLoading', false);
      return res;
    },
    async getForwardEmails() {
      const res = await api.getForwardEmails();
      return res;
    },
    async getAddress(ctx, address) {
      const res = await api.getAddress(address);
      return res;
    },
    async countTotalPeople(ctx, { coordinates, token }) {
      const res = await api.countTotalPeople({ coordinates, token });
      return res;
    },
    async getIncidentsById(ctx, id) {
      const res = await api.getIncidentsById(id);
      return res;
    }
  },
  getters: {}
};

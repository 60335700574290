import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import store from '../store';
import NotFoundPage from '../views/NotFoundPage';
import CategorySettings from '../views/CategorySettings';
import Alert from '../views/Alert';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue')
    component: Admin,

    children: [
      {
        path: '',
        name: 'main',
        component: ObjectList
      },
      {
        path: '/new-alert',
        name: 'new-alert',
        component: Alert
      },
      {
        path: '/details/:id',
        name: 'alert-details',
        component: Alert
      },
      {
        path: '/category-settings',
        name: 'category-settings',
        component: CategorySettings
      },
      { path: '*', name: 'NotFoundPage', component: NotFoundPage }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // if (!!user.access) {
  //   CheckUser(user, next)
  // } else {
 /* store.dispatch('getUserAccesses').then(() => {
    CheckUser(store.getters.userAccesses, next);
  });*/
  next()
  // }
});
/*
const CheckUser = (user, next) => {
  if (user.status <= 0) {
    // не залогинен
    window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
  } else {
    // залогинен
    if (!user.access.role === 'ANONYMOUS') {
      // отправить на главную/ обычный юзер
      window.location.href = '/';
      console.log('отправить на главную, обычный юзер или не залогинненый');
    } else {
      next();
    }
  }
};
*/
export default router;

<template>
  <div id="app">
    <router-view />
    <r-notification />
  </div>
</template>

<script>
import store from './store';

export default {
  name: 'AlertsAdminApp',
  data() {
    return {
      interval: null
    };
  },
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps')
      && document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  },
  async created() {
    // await this.$store.dispatch('getUserAccesses', window.token);
    if (this.$store.state.categories.categories.length) {
      await this.$store.dispatch('getCategories');
    }
    this.interval = setInterval(() => {
      if (true) {
        this.userAccess();
      }
    }, 500);
  },
  methods: {
    async userAccess() {
      clearInterval(this.interval);
      await this.$store.dispatch('getUserAccesses', window.token).then(() => {
        this.CheckUser(this.$store.getters.userAccesses);
      });
    },
    CheckUser(user) {
      if (user.status <= 0) {
        // не залогинен
        // window.location.href = `/login2?redirect=${process.env.VUE_APP_BASE_URL}`;
      } else {
        // залогинен
        if (!user.access.role === 'ANONYMOUS') {
          // отправить на главную/ обычный юзер
          window.location.href = '/';
          console.log('отправить на главную, обычный юзер или не залогинненый');
        }
      }
    }
  }
};
</script>
<style lang="scss">
.flex-column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
</style>

<template>
  <div>
    <admin-header title="Настройка категорий" />
    <div
      class="flex align-start flex-1 mt-8 field_wrapper"
      style="max-width: 460px"
    >
      <div class="flex-1 mr-2">
        <r-input
          label="Новая категория"
          @onPressEnter="addSettings"
          v-model.trim="$v.category.$model"
          :error="$v.category.$error"
          :error-message=" $v.category.$error ?
            !$v.category.required ? 'Поле обязательно для заполнения' : !$v.category.maxLength ? ' Максимальное количество символов — 40' : null

            : null
          "
        />
      </div>
      <r-button-simple
        size="larishae"
        @click="addSettings"
        icon="add"
        :disabled="!category || $v.category.$error"
        :class="{ btn_error: $v.category.$error }"
      />
    </div>
    <selected-list
      v-show="$store.state.categories.categories.length"
      class="mt-6"
    />
    <message ref="message" />
  </div>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import AdminHeader from '../components/AdminHeader';
import SelectedList from '../components/SelectedList';
import Message from "../components/Message";
export default {
  name: 'CategorySettings',
  components: { AdminHeader, SelectedList, Message },
  validations: {
    category: {
      required,
      maxLength: maxLength(40)
    }
  },
  data() {
    return {
      categories: [],
      category: ''
    };
  },
  methods: {
    async addSettings() {
      await this.$store.dispatch('addCategory', this.category).then(res => {
        if (res.error) {
          this.$refs.message.showMessage(res.error, 'error');
        } else {
          this.category = null;
          this.$v.$reset();
          this.$store.dispatch('getCategories');
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">

</style>

import axios from 'axios';
import ApiService from './ApiService';

export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async getUserAccesses(token) {
    const { data } = await axios.get('/auth/rest/access/TELEPHONE_MESSAGES', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    return data;
  }

  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }

  async getIncidents() {
    const { data } = await this._axios.post('/ajax.php?action=getIncidents');
    return data;
  }

  async getIncidentsById(id) {
    const { data } = await this._axios.post(`/ajax.php?action=getIncidents&id=${id}`);
    return data;
  }

  async sendToIncidents(payload) {
    const { data } = await this._axios.post('/ajax.php?action=sendToIncidents', payload);
    return data;
  }

  async operateIncident(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateIncident', payload);
    return data;
  }

  async getIncidentsLog(id) {
    const { data } = await this._axios.get(`/ajax.php?action=getIncidentsLog&incidentId=${id}`);
    return data;
  }

  async getIncidentTypes() {
    const { data } = await this._axios.get('/ajax.php?action=getIncidentTypes');
    return data;
  }

  async operateIncidentType(payload) {
    /* payload --- JSON {
 id:Int|-1 для создания
 action:update|delete
 item:{id:Int,title:String,template:JSON}
 } */
    const { data } = await this._axios.post('/ajax.php?action=operateIncidentType', payload);
    return data;
  }

  async getForwardEmails() {
    const { data } = await this._axios.get('/ajax.php?action=getForwardEmails');
    return data;
  }

  async getUsers({ token, query, limit, skip }) {
    const { data } = await axios.get('/auth/rest/user/list', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      params: {
        query,
        limit,
        skip,
        isActive: true
      }
    });
    return data;
  }

  async countTotalPeople({ coordinates, token }) {
    const JSON = { coord: coordinates };
    const { data } = await axios.post('/ajax.php?action=calcResidentsInArea', JSON, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    return data;
  }

  async getHousesList(pattern, token) {
    const { data } = await this._axios.get('/cityProperty/rest/service/page', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      params: {
        pattern,
        itemCount: 50,
        page: 1,
        extended: true,
        type: ''
      }
    });
    return data;
  }
}

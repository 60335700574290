<template>
  <div class="objects">
    <admin-header
      :title="moduleTitle"
      :back="false"
    />
    <div class="flex mb-6 mt-7">
      <router-link
        class="flex align-center sulguni color-rocky mr-6"
        to="new-alert"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-icon
          size="16"
          class="mr-2"
          icon="add"
          fill="rocky"
        />
        Добавить оповещение
      </router-link>
      <router-link
        class="flex align-center sulguni color-rocky mr-6"
        to="/category-settings"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-icon
          size="16"
          class="mr-2"
          icon="settings"
          fill="rocky"
        />
        Настроить категории
      </router-link>
    </div>
    <div class="flex mb-6 align-center">
      <div class="flex mr-6">
        <div class="icon-div ">
          <r-date-picker
            click-close
            label="Начало"
            v-model="filterStartDate"
          />
          <div
            style="display: table"
            @click="deleteStart"
          >
            <r-icon
              size="16"
              v-show="filterStartDate"
              icon="close"
              fill="titanic"
              class="anie icon-close"
            />
          </div>
        </div>
        <div class="icon-div">
          <r-date-picker
            class="ml-4"
            click-close
            label="Окончание"
            v-model="filterEndDate"
          />
          <div
            style="display: table"
            @click="deleteEnd"
          >
            <r-icon
              size="16"
              v-show="filterEndDate"
              icon="close"
              fill="titanic"
              class="anie icon-close"
            />
          </div>
        </div>
      </div>
      <r-select
        class="flex-1 mr-6"
        :items="sortPattern"
        label="Форма"
        v-model="selectedSortPattern"
      />
      <r-select
        class="flex-1 mr-6"
        :items="sortCategory"
        label="Категория"
        v-model="selectedSortCategory"
      />
      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model.trim="search"
        after-icon="search"
      />

      <r-button-simple
        size="larishae"
        icon="icon-view"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'map' ? 'secondary' : 'primary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <r-button-tag
          class="mr-2"
          v-for="filter of filters"
          :key="filter.id"
          :title="filter.title"
          :color="filter.id === filterSelected.id ? 'rocky' : 'metropolis'"
          :not-shorter="true"
          @click.native="filterSelected = filter"
        />
        <div class="ml-auto parmigiano color-titanic opacity-48">
          {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
        </div>
      </div>

      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          show-type
          @click="onMapClick"
        >
          <div v-if="filteredObjects.length > 0">
            <div
              v-for="item of filteredObjects"
              :key="item.id"
            >
              <ymap-marker
                v-for="(marker,index) of item.details.addresses"
                :key="`_${marker.id}+${index}`"
                :coords="[marker.lat, marker.lng]"
                :marker-id="`marker_${marker.id}+${index}`"
                :icon="$markerIcon('mapPin')"
                :options="{ hideIconOnBalloonOpen: false }"
                cluster-name="main"
                :balloon="{ body: clusterBalloon(marker, item)}"
              >
                <balloon-card
                  slot="balloon"
                  :item="item"
                  :marker="marker"
                  :data-id="marker.id"
                />
              </ymap-marker>
            </div>
          </div>
        </rir-map>
      </div>
      <div
        v-else
        class="objects__list mt-6"
      >
        <card-item
          v-for="card of filteredObjects"
          :key="card.id"
          :card="card"
        />
      </div>
      <not-found v-if="countObjects < 1" />
    </template>
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import moment from 'moment';
import BalloonCard from '../components/BalloonCard';
import CardItem from '../components/Card';
import NotFound from '../components/NotFound';
import { formattedDate, wordMatch, getNumberOfDays } from '../helpers/utils';
import rirMap from '../components/RirMap';
import Loader from '../components/Loader';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    CardItem,
    BalloonCard,
    AdminHeader,
    NotFound,
    rirMap
  },
  data() {
    return {
      filterSelected: {
        id: 'ALL',
        title: 'Все'
      },
      filters: [
        {
          id: 'ALL',
          title: 'Все'
        },
        {
          id: 'INWORK',
          title: 'В работе'
        },
        {
          id: 'READY',
          title: 'Готовые'
        },
        {
          id: 'PLANNING',
          title: 'Запланированные'
        }
      ],
      onlyFinished: false,
      timeoutId: null,
      selectedView: 'table',
      searchText: '',
      sortPattern: [
        {
          id: 0,
          title: 'Все'
        },
        {
          id: 1,
          title: 'ГО ЧС'
        },
        {
          id: 2,
          title: 'Свободная'
        }
      ],
      selectedSortPattern: 0,
      sortCategory: [
        {
          id: 0,
          title: 'Все'
        }
      ],
      selectedSortCategory: 0,
      filterStartDate: null,
      filterEndDate: null,
      filterDoneStartDate: null,
      filterDoneEndDate: null,
      list: [],
      isShowMap: false,
      selectedMarkerId: null
    };
  },
  computed: {
    moduleTitle() {
      return window.moduleTitle || 'Оперативные оповещения';
    },
    isLoading() {
      return this.$store.state.alerts.isLoading;
    },
    filteredObjects() {
      let list = this.$store.state.alerts.alerts;
      // Если заполнены даты
      if (!!this.filterStartDate || !!this.filterEndDate) {
        let start = new Date(this.filterStartDate || new Date(0));
        // start.setDate(start.getDate() - 1);
        let end = new Date(this.filterEndDate || '3000-01-01');
        let endItem = end;
        const dateOffset = 24 * 60 * 60 * 1000;
        const dateFilter = date => {
          start = new Date(start).getTime();
          end = new Date(end).getTime();
          endItem = end + dateOffset;

          return date >= start && endItem >= date;
        };

        list = list.filter(el => dateFilter(el?.details?.dateFrom) && dateFilter(el?.details?.dateTo));
      }
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.details?.patternName || '', this.searchText) <= 0.5
            || wordMatch(el?.details?.firm || '', this.searchText) <= 0.5
            || wordMatch(el?.details?.fio || '', this.searchText) <= 0.5
            || wordMatch(el?.details?.work || '', this.searchText) <= 0.5
            || wordMatch(el?.details?.formName || '', this.searchText) <= 0.5
            || wordMatch(el?.details?.addresses[0]?.address
              || el?.details?.addresses[2]?.address
              || el?.details?.addresses[3]?.address
              || '', this.searchText) <= 0.5
        );
      }
      if (this.selectedSortPattern !== 0) {
        list = list.filter(el => (this.selectedSortPattern === 1 ? el.details.pattern.id === 0 : el.details.pattern.id === 1));
      }
      if (this.selectedSortCategory !== 0) {
        list = list.filter(el => el?.details?.category?.id === this.selectedSortCategory);
      }
      // if (this.onlyFinished) {
      //   list = list.filter(el => el?.details?.dateTo && (new Date(el?.details?.dateTo).getTime() < Date.now()));
      // }
      if (this.filterSelected.id === 'INWORK') {
        list = list.filter(el => {
          const dateFrom = moment(Number(el.details.dateFrom));
          const dateTo = el.details.dateTo ? moment(Number(el.details.dateTo)) : null;
          if (dateTo === null) {
            return true;
          }
          if (el.details?.timeFrom) {
            dateTo.set('hour', Number(el.details?.timeFrom?.slice(0, 2))).set('minute', Number(el.details?.timeFrom?.slice(3, 5)));
          } else {
            dateTo.set('hour', 23).set('minute', 59).set('second', 59);
          }
          if (el.details?.timeTo) {
            dateTo.set('hour', Number(el.details?.timeTo?.slice(0, 2))).set('minute', Number(el.details?.timeTo?.slice(3, 5)));
          } else {
            dateTo.set('hour', 23).set('minute', 59).set('second', 59);
          }
          return moment().isBetween(dateFrom, dateTo);
        });
      }
      if (this.filterSelected.id === 'READY') {
        list = list.filter(el => {
          const dateTo = el.details.dateTo ? moment(Number(el.details.dateTo)) : null;

          if (dateTo === null) {
            return false;
          }
          if (el.details?.timeTo) {
            dateTo.set('hour', Number(el.details?.timeTo?.slice(0, 2))).set('minute', Number(el.details?.timeTo?.slice(3, 5)));
          } else {
            dateTo.set('hour', 23).set('minute', 59).set('second', 59);
          }
          return dateTo.isAfter('2010-10-19');
        });
      }
      if (this.filterSelected.id === 'PLANNING') {
        list = list.filter(el => {
          const date = new Date(el.details.dateFrom);
          date.setHours(Number(el.details?.timeFrom?.slice(0, 2)), Number(el.details?.timeFrom?.slice(3, 5)));
          return getNumberOfDays(date) > 0;
        });
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.$store.dispatch('getAlerts');
    await this.$store.dispatch('getCategories').then(() => {
      this.sortCategory = [
        {
          id: 0,
          title: 'Все'
        },
        ...this.$store.getters.getCategoriesForSelect
      ];
    });
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(Number(date), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
    clusterBalloon(marker, item) {
      return `<a href="alerts/details/${item.id}"
    class="r-map-balloon pa-4 pointer"
  >
    <p class="flex align-center caprino mb-2 opacity-72">
       ${(this.getFormattedDate(item.details.dateFrom) || 'Дата не указана') + (item.details.timeFrom ? `, ${item.details.timeFrom}` : '')} –
                ${(this.getFormattedDate(item.details.dateTo) || 'Дата не указана') + (item.details.timeTo ? `, ${item.details.timeTo}` : '')}

    </p>
    ${
  marker?.address ? `<p
      class="sulguni mb-2"
      v-if=""
    >
      ${marker.address}
    </p>` : null
}

    <p class="mozzarella mb-1 opacity-72">
      ${item.details.work || '—'}
    </p>

    <div class="flex align-center">
    <i class="RIcon icons mr-2 icon-placeholder rir-mayor_16" style="color: var(--rir-rocky);"></i>
      <p class="briscola">
        ${item.firm || '—'}
      </p>
    </div>
  </a>`;
    },
    deleteStart() {
      this.filterStartDate = null;
    },
    deleteEnd() {
      this.filterEndDate = null;
    },
    changePeriod() {
      if (this.selectedSortPeriod === 'period') {
        this.$nextTick(() => {
          document.querySelector('.date-picker__fly').querySelector('.rir-select__icon').click();
        });
      }
    },
    /*  periodFnc() {
        if (this.selectedSortPeriod === 'period') {
          this.selectedPeriod = this.period[0] + '-' + this.period[1];
          this.count++;
        }
      }, */

    onMarkerClick(ids) {
      this.selectedMarker = ids;
    },

    onMapClick() {
      this.selectedMarker = null;
    },

    onBalloonClick() {
      if (!this.selectedMarker) return;

      this.$router.push({ name: 'alert-details', params: { id: this.selectedMarker.item } });
    },

    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarker) return;

      const balloon = this.$refs.map.querySelector(
        `.rir-map-balloon[data-id="${this.selectedMarker.marker}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarker) return;
      const balloon = this.$refs.map.querySelector(
        `.rir-map-balloon[data-id="${this.selectedMarker.marker}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
  z-index: 1;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .rir-popover {
  display: flex;
  align-items: center;

  &__activator {
    width: 100%;
  }

  .rir-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .rir-button {
      display: flex;
      align-items: center;

      .rir-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .rir-popover__content {
  padding: 8px !important;
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

::v-deep .rir-popover__content {
  background: red;
}

.date-picker__fly {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
</style>
<style lang="scss">
.rir-map-balloon {
  width: 244px;
&__img {
   width: 100%;
   aspect-ratio: 16/10;
   object-fit: cover;
 }

&__info {
   padding: 12px 16px 0;
 }
}
.r-map-balloon {
  width: 244px;
  display: block;
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__info {
    padding: 12px 16px 0;
  }
}

</style>

<template>
  <div
    :class="['r-map', { 'r-map--fixed': !isCollapsed }]"
    ref="map"
  >
    <div
      v-if="search"
      class="r-map__search"
    >
      <r-input
        class="cleaning__input-map-search"
        label="Поиск по адресу"
        :value="value"
        @input="$emit('input', $event)"
      >
        <template #after>
          <r-icon
            size="16"
            icon="search"
            fill="pianist"
          />
        </template>
      </r-input>
    </div>
    <yandex-map
      v-if="isShow && isYmapsReady"
      :coords="center || $cityCenter"
      :zoom.sync="mapZoom"
      @click="onMapClick"
      @dblclick="onMapDblClick"
      :map-type="mapType"
      :options="mapOptions"
      :behaviors="['drag', 'scrollZoom', 'multiTouch']"
      :controls="mapControls"
      @map-was-initialized="onMapInit"
      :cluster-options="clusterOptions"
      :cluster-callbacks="clickOnCluster"
      @contextmenu="onRightClick"
      @markers-was-add="$emit('markersWasAdd', $event)"
      @markers-was-change="$emit('markersWasChange', $event)"
    >
      <slot />
    </yandex-map>

    <div
      v-show="control"
      class="r-map__controls"
    >
      <r-button-simple
        v-if="collapseBtn"
        class="mb-4 amelie"
        :icon="isCollapsed ? 'fullscreen' : 'exit-fullscreen'"
        fill="rocky"
        icon-size="20"
        @click="onCollapseMap"
        type="light"
        size="larishae"
        title=""
      />
      <drop-down-map-button
        v-if="showType"
        class="mb-4"
        :items="mapViewTypes"
        @select="onChangeView"
      />
      <r-button-simple
        type="light"
        size="larishae"
        icon="add"
        class="amelie"
        fill="rocky"
        icon-size="20"
        @click="onZoom(1)"
        title=""
      />
      <r-button-simple
        type="light"
        size="larishae"
        class="mt-1 mb-4 amelie"
        icon="remove"
        fill="rocky"
        icon-size="20"
        @click="onZoom(-1)"
        title=""
      />
      <r-button-simple
        type="light"
        size="larishae"
        v-if="locationBtn"
        icon="location"
        icon-size="20"
        @click="onLocation"
        title=""
      />
    </div>
  </div>
</template>

<script>
import BalloonCard from './BalloonCard.vue';
import DropDownMapButton from './DropDownMapButton.vue';
import { loadYmap } from '../plugins/vue-yandex-maps/vue-yandex-maps.umd';

export default {
  name: 'RirMap',
  components: {
    BalloonCard,
    DropDownMapButton,
    loadYmap
  },
  props: {
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, null],
      default: null
    },
    control: {
      type: Boolean,
      default: true
    },
    controls: {
      type: Array,
      default: () => []
    },
    showType: {
      type: Boolean,
      default: false
    },
    showTraffic: {
      type: Boolean,
      default: false
    },
    collapseBtn: {
      type: Boolean,
      default: true
    },
    locationBtn: {
      type: Boolean,
      default: false
    },
    center: {
      type: Array
    },
    zoom: {
      type: Number,
      default: 12
    },
    maxZoom: {
      type: Number,
      default: 18
    },
    minZoom: {
      type: Number,
      default: 10
    },

    dblclickTimeout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      mapViewTypes: [
        { id: 'map', active: true, value: 'Схема' },
        { id: 'satellite', active: false, value: 'Спутник' },
        { id: 'hybrid', active: false, value: 'Гибрид' }
      ],
      mapType: 'map',
      isShow: true,
      mapControls: this.controls, // [] - Скрыть все элементы управления картой. ['fullscreenControl'] - кнопка развернуть на весь экран
      mapOptions: {
        // restrictMapArea: [
        //   [54.982611, 43.223491],
        //   [54.817007, 43.482356]
        // ],
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: true
      },
      clickOnCluster: {
        main: {
          click(e) {
            const target = e.get('target');
            const customItemContentLayout = window.ymaps.templateLayoutFactory.createClass(
              '<div>{{geoObject.properties.balloonContentBody|raw}}</div>'
            );
            target.options.set({ clusterBalloonItemContentLayout: customItemContentLayout });
          }
        }
      },
      clusterOptions: {
        // Название свойства = название кластера (к которому будет применен данный стиль)
        main: {
          clusterDisableClickZoom: false,
          clusterOpenBalloonOnClick: true,

          // Иконка кластера
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ],
          clusterBalloonContentLayout: 'cluster#balloonCarousel',
          hideIconOnBalloonOpen: false,
          clusterBalloonPagerType: 'marker',
          clusterBalloonContentLayoutWidth: 244,
          clusterBalloonContentLayoutHeight: 164
        },
        orgs: {
          clusterOpenBalloonOnClick: false,
          clusterIcons: [
            {
              href: `${this.$S3_COMMON}/icons/map/cluster.svg`,
              size: [56, 56],
              offset: [0, 0]
            }
          ]
        }
      },

      mapZoom: this.zoom,
      mapInstanse: null,
      timeout: null,
      isCollapsed: true,
      timeout: null,
      isYmapsReady: false
    };
  },

  watch: {
    showTraffic(val) {
      const isTrafficShown = !!this.mapInstanse.controls.get('trafficControl').isTrafficShown();

      // если не включен контрол трафика
      if (!this.mapInstanse.controls.get('trafficControl')) return;

      if (val) {
        if (isTrafficShown) return;
        this.mapInstanse.controls.get('trafficControl').showTraffic();
      } else {
        this.mapInstanse.controls.get('trafficControl').hideTraffic();
      }
    }
  },

  async mounted() {
    // настройки карты
    const settings = {
      coordorder: 'latlong'
    };

    !window.ymaps && (await loadYmap(settings));
    this.isYmapsReady = true;
  },

  beforeDestroy() {
    this.mapInstanse && this.mapInstanse.destroy();
  },

  methods: {
    onCollapseMap() {
      this.isCollapsed = !this.isCollapsed;
      this.$nextTick(() => {
        this.mapInstanse.container.fitToViewport();
      });
    },

    onChangeView(obj) {
      // Не хочет менять подложку без выключения
      this.isShow = false;
      this.mapType = obj.id;
      this.mapViewTypes = this.mapViewTypes.map(el => ({ ...el, active: el.id === obj.id }));
      this.$nextTick(() => {
        this.isShow = true;
      });
    },

    onMapInit(e) {
      // console.log('mapInit | objects - ', e.geoObjects.getLength(), e.geoObjects);
      this.mapInstanse = e;
      this.$emit('onInit', this.mapInstanse);
      this.setObserver();
      this.mapInstanse.options.set('openBalloonOnClick', false);
      // console.log('map', e);
    },

    onZoom(val) {
      const newZoom = this.mapZoom + val;
      if (newZoom <= this.maxZoom && newZoom >= this.minZoom) this.mapZoom = newZoom;
    },
    onLocation() {
      // console.log('length', this.mapInstanse.geoObjects.getLength());
      // this.mapInstanse.geoObjects.each(function(collection) {
      //   console.log('collectionEach', collection.each);
      //   const checkMarker = marker => {
      //     const markerId = marker.properties.get('markerId');
      //   };
      //   collection.each(checkMarker);
      // });
      // this.mapInstanse.geoObjects.each(function(geoObject) {
      //   // if (geoObject.properties.get('id') == 'some id') {
      //   // return false;
      //   // }
      // });
      //   console.log(
      //     'OM',
      //     this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.getById('1263')
      //   );
      // this.mapInstanse.geoObjects
      //   .get(0)
      //   .clusters.state.set(
      //     'activeObject',
      //     this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.getById('1263')
      //   );
      //   console.log('iterator', this.mapInstanse.geoObjects.getIterator().getNext());
      //   console.log(
      //     '1269',
      //     this.mapInstanse.geoObjects.get(0).clusters._objectManager.objects._objectsById['1263']
      //   );
      //   // Открыть balloon с id = 1386
      //   this.mapInstanse.geoObjects.get(0).objects._objectManager.objects.balloon.open('1386');
      //   this.mapInstanse.container.fitToViewport();
      //         <BalloonCard
      //     slot="balloon"
      //     :title="marker.title"
      //     :address="marker.address"
      //     :img="marker.img"
      //     :data-id="marker.uid"
      //   >
      //   console.log('objects', this.mapInstance.geoObjects.get(0).get(n).balloon.open());
      //   this.mapInstanse.objects.balloon.open(1);
      //   this.mapInstanse.balloon.open(this.mapInstanse.getCenter(), BalloonCard);
    },
    onMapClick(e) {
      //   if (this.markers.length) return;
      const coords = e.get('coords');
      this.coords = coords;
      //   Закрываем открытый Balloon при клике по карте

      if (this.dblclickTimeout) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
          this.$emit('click', coords);
        }, this.dblclickTimeout);
      } else {
        this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
        this.$emit('click', coords);
      }
    },

    onMapDblClick(e) {
      const coords = e.get('coords');
      //   Закрываем открытый Balloon при клике по карте
      if (this.dblclickTimeout && this.timeout) clearTimeout(this.timeout);
      this.mapInstanse.balloon.isOpen() && this.mapInstanse.balloon.close();
      this.$emit('dblclick', coords);
    },

    onRightClick(e) {
      this.$emit('onRightClick', e);
    },

    setObserver() {
      //   Вызываем метод fitToViewport если изменились размеры родительского элемента
      const myObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.mapInstanse.container.fitToViewport();
            // console.log('width', entry.contentRect.width);
          }, 50);
        });
      });

      myObserver.observe(this.$refs.map.parentElement);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}
::v-deep .ymaps-2-1-79-balloon__layout {
  overflow: visible !important;
  background: transparent !important;
}
::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;

  & > [id^="id_"] {
    overflow: visible !important;
  }
}
::v-deep .ymaps-2-1-79-b-cluster-carousel__nav {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__nav_type_prev {
  left: -16px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='16' viewBox='0 0 6 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.83776 2.04161C6.13682 1.57729 6.00285 0.958464 5.53854 0.65941C5.37077 0.551352 5.18282 0.49983 4.99708 0.5C4.66876 0.500296 4.34732 0.662091 4.15633 0.958637L0.667321 6.37568C0.665633 6.3783 0.663949 6.38092 0.66227 6.38355L0.318578 6.91716C-0.106193 7.57667 -0.106193 8.4236 0.31858 9.0831L0.662295 9.61675C0.663967 9.61937 0.665643 9.62198 0.667324 9.62459L4.15633 15.0416C4.26847 15.2157 4.4256 15.3434 4.60267 15.4192C4.85237 15.5261 5.14174 15.5298 5.40067 15.4152C5.44778 15.3944 5.49387 15.3696 5.53854 15.3408C6.00285 15.0418 6.13682 14.4229 5.83776 13.9586L2.34874 8.54162C2.34777 8.5401 2.34679 8.53858 2.34583 8.53706C2.13733 8.20949 2.13733 7.79076 2.34583 7.46319L5.83776 2.04161Z' fill='%233D75E4'/%3e%3c/svg%3e ")!important;
  background-position: 48% 50% !important;
  background-repeat: no-repeat;
}
::v-deep .ymaps-2-1-79-b-cluster-carousel__nav_type_next {
  right: -16px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='16' viewBox='0 0 6 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.16234 2.04161C-0.136714 1.57729 -0.00274534 0.958464 0.461566 0.65941C0.629338 0.551352 0.817283 0.49983 1.00303 0.5C1.33134 0.500296 1.65278 0.662091 1.84378 0.958637L5.33278 6.37568C5.33447 6.3783 5.33615 6.38092 5.33783 6.38355L5.68153 6.91716C6.1063 7.57667 6.1063 8.4236 5.68152 9.0831L5.33781 9.61675C5.33614 9.61937 5.33446 9.62198 5.33278 9.62459L1.84378 15.0416C1.73163 15.2157 1.57451 15.3434 1.39743 15.4192C1.14774 15.5261 0.858359 15.5298 0.599431 15.4152C0.552327 15.3944 0.506231 15.3696 0.461565 15.3408C-0.0027463 15.0418 -0.136714 14.4229 0.162341 13.9586L3.65136 8.54162C3.65234 8.5401 3.65331 8.53858 3.65428 8.53706C3.86278 8.20949 3.86278 7.79076 3.65427 7.46319L0.16234 2.04161Z' fill='%233D75E4'/%3e%3c/svg%3e ") !important;
  background-position: 54% 50% !important;
  background-repeat: no-repeat;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__separator {
  display: none !important;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel_pager_marker .ymaps-2-1-79-b-cluster-carousel__pager-item {
  background: #E4EDFB !important;
  width: 4px !important;
  height: 4px !important;
  margin: 0 2px !important;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel_pager_marker .ymaps-2-1-79-b-cluster-carousel__pager-item.ymaps-2-1-79-b-cluster-carousel__pager-item_current_yes,
::v-deep .ymaps-2-1-79-b-cluster-carousel_pager_marker .ymaps-2-1-79-b-cluster-carousel__pager-item:hover {
  background: #3D75E4 !important;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__pager {
  bottom: 0px !important;
  left: 0;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel {
  padding: 0!important;
  margin: 0 !important;
  border: none!important;
}

::v-deep .ymaps-2-1-79-b-cluster-carousel__content,
::v-deep .ymaps-2-1-79-balloon__content > [id^=id_],
::v-deep .ymaps-2-1-79-b-cluster-carousel{
  height: auto!important;
}
.r-map {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  &__search {
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    top: 16px;
    left: 16px;
    display: flex;
    width: 255px;
    background-color: #fff;
    box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
    > * {
      background-color: #fff;
    }
  }
  &__controls {
    position: absolute;
    top: 16px;
    right: 16px;
    > * {
      width: 40px;
      height: 40px;
      box-shadow: 0 4px 16px rgb(4 21 62 / 16%);
    }
    &--fixed {
      position: fixed;
      right: 16px;
      top: 16px;
      z-index: 10001;
    }
  }
  &--fixed {
    background-color: #ccc;
    border-radius: unset;
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

@media screen and (max-width: 1023px) {
  .r-map {
    &__search {
      display: none;
    }
  }
}
.r-map__controls{
  display: flex;
  flex-direction: column;
}
// yaMap
::v-deep .ymap-container {
  width: 100%;
  height: 100%;
}
::v-deep .ymaps-2-1-79-map {
  max-height: 100%;
  overflow: hidden;
}

::v-deep .ymaps-2-1-79-controls-pane {
  display: none;
}

// Стилизация PopUp'a
::v-deep .ymaps-2-1-79-balloon {
  bottom: calc(99% - -14px);
  top: unset;
  box-shadow: 0px 9px 28px rgb(17 48 121 / 18%);
  border-radius: 16px 16px 16px 0px;
  //overflow: hidden;
  transform: translate(66px, -10px);
  padding: 0;
  margin: 0;
}

// Скрытие "хвостика" PopUp'a
::v-deep .ymaps-2-1-79-balloon__tail,
.ymaps-2-1-79-balloon__tail:after {
  display: none;
}

// Иконка закрытия Baloon'a
::v-deep .ymaps-2-1-79-balloon__close-button {
  display: none;
}

::v-deep .ymaps-2-1-79-balloon__content {
  margin: 0 !important;
  padding: 0 !important;
}
::v-deep .ymaps-2-1-79-image{
  left: -28px!important;
}
</style>

<style>
/* HTML элемент иконки кластера */
.ymaps-2-1-79-default-cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* HTML элемент в котором лежит цифра (кол-во элементов кластера) */
.ymaps-2-1-79-default-cluster > * {
  color: #ffffff;
  font-weight: bold;
  font-family: 'Golos UI';
  font-size: 24px;
  line-height: 28px;
}

/* Скрытие кнопки на весь экран */
.ymaps-2-1-79-float-button {
  display: none !important;
}
</style>

<template>
  <router-link
    :to="`/details/${item.id}`"
    class="r-map-balloon pa-4 pointer"
  >
    <p class="flex align-center caprino mb-2 opacity-72">
      {{ getFormattedDate(item.details.dateFrom) || "Не указано" }} –
      {{ getFormattedDate(item.details.dateTo) || "Не указано" }}
    </p>

    <p
      class="sulguni mb-2"
      v-if="marker.address"
    >
      {{ marker.address }}
    </p>

    <p class="mozzarella mb-1 opacity-72">
      {{
        item.details.description || "—"
      }}
    </p>

    <div class="flex align-center">
      <r-icon
        class="mr-2"
        icon="mayor"
        fill="rocky"
        size="16"
      />
      <p class="briscola">
        {{ item.firm || "—" }}
      </p>
    </div>
  </router-link>
</template>

<script>
import { formattedDate } from '../helpers/utils';

export default {
  name: 'BalloonCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    marker: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(Number(date), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 244px;
  display: block;
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
  }

  &__info {
    padding: 12px 16px 0;
  }
}

</style>

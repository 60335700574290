<template>
  <div class="readable">
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Форма
      </p>
      <p class="feta opacity-72 mt-1">
        {{ alert.details.patternName || "—" }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Название
      </p>
      <p class="feta opacity-72 mt-1">
        {{ alert.details.formName || "—" }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Категория
      </p>
      <p class="feta opacity-72 mt-1">
        {{ alert.details.categoryData.value || "—" }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        {{ alert.details.pattern === 0 ? "Организация" : "Организация или дежурный" }}
      </p>
      <p class="feta opacity-72 mt-1">
        {{ alert.details.firm || "—" }}
      </p>
    </div>
    <div
      class="mb-4"
      v-if="alert.details.pattern === 0"
    >
      <p class="mozzarella opacity-48">
        ФИО ответственного
      </p>
      <p class="feta opacity-72 mt-1">
        {{ alert.details.fio || "—" }}
      </p>
    </div>
    <div
      class="mb-4"
      v-if="alert.details.pattern === 0"
    >
      <p class="mozzarella opacity-48">
        Должность ответственного
      </p>
      <p class="feta opacity-72 mt-1">
        {{ alert.details.job || "—" }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        {{ alert.details.pattern === 0 ? "Телефон ответственного" : "Телефон для связи" }}
      </p>
      <p
        class="feta opacity-72 mt-1"
        v-for="phone of alert.details.phones"
        :key="phone.id"
      >
        <r-icon
          class="mr-2"
          icon="telephone"
          size="16"
        />
        {{ phone.value }}
      </p>
    </div>
    <div
      class="mb-4"
      v-if="alert.details.pattern === 0"
    >
      <p class="mozzarella opacity-48">
        Характер работ
      </p>
      <p class="feta opacity-72 mt-1">
        {{ alert.details.work || "—" }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Время проведения работ
      </p>
      <p class="feta opacity-72 mt-1">
        {{
          getFormattedDate(alert.details.dateFrom)
        }} – {{ getFormattedDate(alert.details.dateTo) }}
      </p>
    </div>
    <div class="mb-4">
      <p class="mozzarella opacity-48">
        Текст сообщения
      </p>
      <p class="feta opacity-72 mt-1">
        {{ alert.details.description || "—" }}
      </p>
    </div>
  </div>
</template>

<script>
import { formattedDate } from '../helpers/utils';

export default {
  name: 'AlertOnlyRead',
  props: {
    alert: {
      type: Object,
      required: true
    }
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(Number(date), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    }
  }
};
</script>

<style scoped>

</style>

<template>
  <div>
    <h3
      class="camembert my-4"
      v-if="!historyArray.length"
    >
      История не найдена
    </h3>
    <div
      v-else
      v-for="history of historyArray"
      :key="history.id"
      class="mt-6"
    >
      <p class="mozzarella opacity-48 mb-1">
        {{ history.notified === '1' ? 'Сохранено и отправлено: ' : 'Сохранено: ' }}
        {{ history.username }}, {{ getFormattedDate(history.created) }}
      </p>
      <p
        class="parmigiano opacity-72 mb-3"
        v-html="history.comment"
      />

      <accordion-button
        v-if="history.recipients.length && history.notified === '1'"
        label="Адресаты"
      >
        <div
          class="py-4 grid"
          style="border-top: 1px solid #E4EDFB"
          v-for="recipient of history.recipients"
          :key="recipient.id"
        >
          <p class="mozzarella">
            {{ recipient.username }}
          </p>
          <div>
            <p
              class="mozzarella"
              v-if="recipient.emails"
            >
              {{ recipient.emails[0] }}
            </p>
            <p
              class="mozzarella"
              v-if="recipient.phones"
            >
              {{ recipient.phones[0] }}
            </p>
          </div>
        </div>
      </accordion-button>
    </div>
  </div>
</template>

<script>
import Api from '@/api/Api';
import AccordionButton from './AccordionButton';
import { formattedDate } from '../helpers/utils';

export default {
  name: 'AlertHistoryTab',
  components: { AccordionButton },
  data() {
    return {
      historyArray: []
    };
  },
  async mounted() {
    await new Api().getIncidentsLog(this.$route.params.id).then(res => {
      if (res?.all) {
        this.historyArray = res.all;
        this.count++;
      }
    });
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(date, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    }
  }
};
</script>

<style scoped lang="scss">
.camembert{
  text-align: center;
}
::v-deep .grid {
  width: 100%;
  max-width: 620px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  div {
    p:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>

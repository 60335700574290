<template>
  <div>
    <r-button-action
      transparent
      @click.native="isOpen = !isOpen"
      class="align-center flex pointer mb-3"
      :is-arrow="true"
      :title="label"
    />
    <transition name="fade">
      <div v-if="isOpen">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AccordionButton',
  props: {
    label: {
      type: String,
      default: 'Открыть'
    }
  },
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style scoped>
.icon{
  transition: .2s;
}
.open {
  transform: rotate(180deg);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>

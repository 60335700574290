<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <admin-header
      :title="
        !notFound ? (!isEditPage ? 'Новое оперативное оповещение' : details.formName || 'Оперативное оповещение'): null
      "
    >
      <template v-if="!notFound">
        <sup
          class="text-c13 color-fargo"
          v-if="!checkChange && this.isEditPage && this.$store.getters.userIsRoot"
        >Не сохранено</sup>
      </template>
    </admin-header>
    <loader v-if="isLoading" />
    <div v-else-if="!notFound">
      <p
        v-if="isEditPage"
        class="feta opacity-48 mt-2"
      >
        Создано {{ alert.created || 'Не указано' }}, ред. {{ alert.updated || 'Не указано' }}
      </p>
      <r-tabs
        v-if="isEditPage"
        class="mt-6"
        :items="editTabs"
        v-model="activeTabId"
      />
      <div v-show="activeTabId.id === 'information'">
        <div class="form mt-8 flex">
          <div
            v-if="this.$store.getters.userIsRoot"
            class="flex flex-1 flex-column"
          >
            <r-select
              :items="[
                {
                  id: 0,
                  title: 'ГО ЧС',
                },
                {
                  id: 1,
                  title: 'Свободный',
                },
              ]"
              @change="onChangePattern"
              label="Форма"
              v-model.trim="$v.details.pattern.$model"
              :error="$v.details.pattern.$error && !$v.details.pattern.required"
              :class="{'field_error': $v.details.pattern.$error && !$v.details.pattern.required}"
              :return-object="true"
              error-message="Поле обязательно для заполнения"
            />
            <r-input
              class="mt-6"
              label="Название"
              v-model="details.formName"
              @blur="isChange('formName')"
            />
            <div class="field_wrapper">
              <r-select
                class="mt-6"
                :items="$store.getters.getCategoriesForSelect"
                @input="onChangeCategory"
                label="Категория"
                :return-object="true"
                v-model.trim="details.category"
              />
            </div>
            <r-input
              class="mt-10"
              :label="details.pattern.id === 0 ? 'Организация' : 'Организация или дежурный'"
              v-model="details.firm"
              @blur="isChange('firm')"
            />
            <r-input
              v-if="details.pattern.id === 0"
              class="mt-6"
              label="ФИО ответственного"
              v-model="details.fio"
              @blur="isChange('fio')"
            />
            <r-input
              v-if="details.pattern.id === 0"
              class="mt-6"
              label="Должность ответственного"
              v-model="details.job"
              @blur="isChange('job')"
            />
            <div class="flex mt-6 ">
              <r-input
                class="mr-2 flex-1"
                :label="details.pattern.id === 0 ? 'Телефон ответственного' : 'Телефон для связи'"
                v-model="phone"
                v-mask="'+7 (###) ###-##-##'"
              />
              <r-button-simple
                size="larishae"
                icon="add"
                :disabled="!phone"
                @click="addPhone"
              />
            </div>
            <default-selected-list
              v-show="details.phones.length"
              class="mt-4"
              icon="telephone"
              v-model="details.phones"
              text-value="value"
            />
            <r-input
              v-if="details.pattern.id === 0"
              class="mb-6 mt-10"
              label="Характер работ"
              v-model="details.work"
              @blur="isChange('work')"
            />
            <div class="flex align-center mt-6">
              <div class="flex align-items-center mr-6 flex-1">
                <r-date-picker
                  class="flex-1 mr-3"
                  is-time
                  click-close
                  label="Дата начала"
                  :is-timestamp="true"
                  v-model.trim="$v.details.dateFrom.$model"
                  @input="isChange('dateFrom')"
                  :error="$v.details.dateFrom.$error && !$v.details.dateFrom.required"
                  :class="{'field_error': $v.details.dateFrom.$error && !$v.details.dateFrom.required}"
                  error-message="Поле обязательно для заполнения"
                  :isClearModel="true"
                />
                <r-time-picker
                  class="flex-1"
                  label="Время начала"
                  :error="$v.details.timeFrom.$error && !$v.details.timeFrom.required"
                  :class="{'field_error': $v.details.timeFrom.$error && !$v.details.timeFrom.required}"
                  error-message="Поле обязательно для заполнения"
                  v-model.trim="$v.details.timeFrom.$model"
                  @input="isChange('timeFrom')"
                  :isClearModel="true"
                />
              </div>
              <div class="flex align-items-center flex-1">
                <r-date-picker
                  is-time
                  class="flex-1 mr-3"
                  :disabled="!details.dateFrom"
                  :start-date="$v.details.dateFrom.$model"
                  click-close
                  label="Дата окончания"
                  v-model="details.dateTo"
                  @input="isChange('dateTo')"
                  :is-timestamp="true"
                  :isClearModel="true"
                />
                <r-time-picker
                  class="flex-1"
                  :error="false"
                  :disabled="!details.dateTo"
                  label="Время окончания"
                  :error-message="null"
                  v-model.trim="details.timeTo"
                  @input="isChange('timeTo')"
                  :isClearModel="true"
                />
              </div>
            </div>
            <r-textarea
              class="mt-6"
              @input="isChange('description')"
              :rows="7"
              :label="details.pattern.id === 0 ? 'Возможные ограничения услуг' : 'Текст оповещения'"
              v-model.trim="$v.details.description.$model"
              :error="$v.details.description.$error && !$v.details.description.required"
              :class="{'field_error': $v.details.description.$error && !$v.details.description.required}"
              error-message="Поле обязательно для заполнения"
            />
            <h3 class="taleggio mt-10">
              Адресаты
            </h3>

            <r-button-action
              transparent
              @click.native="openContactsModal"
              class="mt-6 inline-flex width-max"
              title="Контакты служебных пользователей"
              icon="add"
              color="rocky"
            />
            <p
              class="flex align-center color-rocky briscola mt-4 ml-6 pointer"
              v-if="details.choicestEmails.length < 1 && details.choicestPhones.length < 1"
              @click="openContactsModal"
            >
              <r-icon
                size="16"
                class="mr-2"
                icon="list"
                fill="rocky"
              />
              Последние выбранные адресаты
            </p>

            <template v-else>
              <p class="flex align-center feta mt-4">
                <r-icon
                  size="16"
                  class="mr-2"
                  icon="mail"
                  fill="rocky"
                />
                <span class="opacity-72">Выбрано имейлов: {{ details.choicestEmails.length }}</span>
              </p>
              <p class="flex align-center feta mt-4">
                <r-icon
                  size="16"
                  class="mr-2"
                  icon="telephone"
                  fill="rocky"
                />
                <span class="opacity-72">Выбрано телефонов: {{ details.choicestPhones.length
                  }}</span>
              </p>
            </template>

            <div class="flex align-center">
              <r-button-action
                transparent
                @click.native="openResidentAddresseesModal"
                class="mt-6 inline-flex width-max"
                title="Адреса жителей"
                icon="add"
                color="rocky"
              />
              <!-- <sup class="ml-1">
                <r-tooltip
                  activator-hover
                  color="amelie"
                  title="Подписавшиеся жители с указанным адресом получат уведомление о начале работ"
                  position="top-center"
                  max-width="400px"
                  :is-arrow="true"
                >
                  <template #activator>
                    <r-icon
                      size="16"
                      icon="help"
                      fill="titanic"
                      class="pointer opacity-48"
                    />
                  </template>
                </r-tooltip>
              </sup>-->
            </div>

            <p
              class="flex align-center feta mt-4"
              v-if="details.residentAddressees.length > 0"
            >
              <r-icon
                size="16"
                class="mr-2"
                icon="mail"
                fill="rocky"
              />
              <span class="opacity-72">Выбрано адресов: {{ details.residentAddressees.length
                }}</span>
            </p>
            <div
              class="flex align-center mt-4"
              v-if="details.residentAddressees.length > 0"
            >
              <r-input
                class="flex-1 mr-2"
                label="Количество человек в зоне"
                v-model="details.peopleTotal"
                @blur="isChange('peopleTotal')"
              />
              <r-button
                class="flex-1"
                :disabled="peopleLoading"
                style="max-width: 115px"
                type="secondary"
                @click="countPeople"
                title="Посчитать"
                :is-loading="peopleLoading"
              />
            </div>
            <h2 class="taleggio mt-10 mb-4">Фото работ</h2>
            <upload-file-input
              :count-file="5"
              upload-url="/uploadfile/index.php"
              :value="details.attachments"
              @input-file="file($event, 'img')"
              :accept-file="['image/png', 'image/jpeg', 'image/gif']"
              @input="uploadFile($event, 'img')"
              sub-title="или перетяните сюда до 5 фотографий, общим объёмом не более 50 МБайт"
            />
            <r-button-action
              v-if="isEditPage && this.$store.getters.userIsRoot"
              transparent
              @click="onDelete"
              class="mt-10 inline-flex width-max color-fargo"
              title="Удалить оповещение"
              icon="delete"
              color="fargo"
              size="sulguni"
            />
          </div>
          <alert-only-read
            class="flex flex-1 flex-column"
            v-else
            :alert="alert"
          />
          <div class="ml-6 flex flex-column flex-1">
            <rir-map
              @click="onMapClick"
              collapse-btn
              style="height: 415px"
              class="mb-6"
            >
              <ymap-marker
                v-if="markerCoords || (addMap && address)"
                :coords="(addMap && address) ? [address.lat, address.lng] : markerCoords"
                :marker-id="'marker'"
                :icon="$markerIcon('mapPin')"
                :options="{ draggable: true }"
                @dragend="onMarkerDragEnd"
              />
              <div v-if="details.addresses.length">
                <ymap-marker
                  v-for="marker of details.addresses"
                  :key="`alert_${marker.id}`"
                  :coords="[marker.lat, marker.lng]"
                  :marker-id="`marker_${marker.id}`"
                  :icon="$markerIcon('mapPin')"
                />
              </div>
            </rir-map>
            <div
              class="flex align-center field_wrapper"
              v-if="this.$store.getters.userIsRoot"
            >
              <r-input
                class="mr-2 flex-1"
                label="Адрес проведения работ"
                v-model.trim="searchAddress"
                @onPressEnter="onSearchAddress(searchAddress, true)"
                @blur="onSearchAddress(searchAddress, false)"
                after-icon="search"
              />
              <r-button-simple
                size="larishae"
                icon="add"
                :disabled="!lat && !lng"
                @click="onSearchAddress(searchAddress, true)"
              />
            </div>
            <div v-else>
              <p class="mozzarella opacity-48">
                Адрес
              </p>
              <template v-if="!!details.addresses.length">
                <p
                  class="feta opacity-72 mt-1"
                  v-for="address of details.addresses"
                  :key="address.id"
                >
                  <r-icon
                    class="mr-2"
                    icon="geopoint"
                    size="16"
                  />
                  {{ address.address }}
                </p>
              </template>
              <p
                v-else
                class="feta opacity-72 mt-1"
              >
                —
              </p>
            </div>
            <default-selected-list
              v-if="this.$store.getters.userIsRoot"
              v-show="details.addresses.length"
              style="margin-right: 52px"
              class="mt-4"
              icon="geopoint"
              v-model="details.addresses"
              text-value="address"
            />
            <r-checkbox
              v-if="details.addresses.length > 0"
              class="mt-4"
              v-model="details.public"
              title="Доступно в публичной части"
              @input="funcCheck"
              :disabled="this.$store.getters.userIsRootOperator"
            />
          </div>
        </div>
        <div
          v-if="this.$store.getters.userIsRoot"
          class="button_container flex align-center"
        >
          <r-button
            width="wide"
            @click="submit(false)"
            :disabled="checkChange"
            :is-loading="isLoading"
            title="Сохранить"
          />
          <r-button
            v-if="alert.totalNotified < 1"
            class="ml-6"
            width="wide"
            type="secondary"
            @click="submit(true)"
            :disabled="checkChange"
            :is-loading="isLoading"
            title="Сохранить и отправить"
          />
        </div>
      </div>
      <div v-show="activeTabId.id === 'sent'">
        <!--<drop-down-button
          :items="periodList"
          @select="onChangePeriod"
          class="mt-6"
        >
          <template>
            <button class="pointer color-rocky sulguni mr-2 align-center">
              <r-icon
                size="16"
                class="mr-1"
                icon="calendar"
                fill="rocky"
              />
              {{ selectedPeriod }}
            </button>
          </template>
        </drop-down-button>-->
        <alert-history-tab :history-array="historyLog" />
      </div>
    </div>
    <not-found v-else />
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
    <message ref="message" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';
import AdminHeader from '../components/AdminHeader';
import RirMap from '../components/RirMap';

import ModalContacts from '../components/ModalContacts';
import ModalResidentAddressees from '../components/ModalResidentAddressees';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';
import AlertHistoryTab from '../components/AlertHistoryTab';
import { formattedDate } from '../helpers/utils';
import defaultSelectedList from '../components/DefaultSelectedList';
import alertOnlyRead from '../components/AlertOnlyRead';
import Loader from '../components/Loader';
import Message from '../components/Message';
import NotFound from '../components/NotFound';
import uploadFileInput from "@/components/UploadFileInput.vue";

export default {
  name: 'Alert',
  components: { uploadFileInput, Loader, AdminHeader, RirMap, AlertHistoryTab, defaultSelectedList, alertOnlyRead, Message, NotFound },
  data() {
    return {
      isCheck: true,
      alert: {
        totalNotified: 0,
        details: {
          pattern: {
            id: 0,
            title: 'ГО ЧС'
          },
          patternName: 'ГО ЧС',
          formName: 'ГО ЧС',
          category: null,
          firm: null,
          fio: null,
          job: null,
          phones: [],
          dateFrom: null,
          dateTo: null,
          timeFrom: null,
          timeTo: null,
          description: null,
          coordinates: null,
          work: null,
          choicestEmails: [],
          choicestPhones: [],
          residentAddressees: [],
          addresses: [],
          peopleTotal: null,
          public: false,
          recipients: [],
          categoryData: {
            value: null
          }
        }
      },
      activeTabId: {
        id: 'information',
        title: 'Информация'
      },
      editTabs: [
        {
          id: 'information',
          title: 'Информация'
        },
        {
          id: 'sent',
          title: 'Отправленные'
        }
      ],
      selectedPeriod: 'За все время',
      periodList: [
        { id: 'all', active: true, value: 'За все время' },
        { id: 'year', active: false, value: 'Текущий год' }
      ],
      isSave: false,
      flyButton: null,
      isLoading: false,
      isMapReady: false,
      addMap: false,
      address: null,
      phone: null,
      emptyFormData: {},
      searchAddress: null,
      lat: null,
      lng: null,
      initialData: {},
      peopleLoading: false,
      notFound: false,
      historyLog: [],
      details: {
        attachments: [],
        public: false,
        pattern: {
          id: 0,
          title: 'ГО ЧС'
        },
        patternName: 'ГО ЧС',
        formName: 'ГО ЧС',
        category: null,
        firm: null,
        fio: null,
        job: null,
        phones: [],
        dateFrom: null,
        dateTo: null,
        timeFrom: null,
        timeTo: null,
        description: null,
        coordinates: null,
        work: null,
        choicestEmails: [],
        choicestPhones: [],
        residentAddressees: [],
        addresses: [],
        peopleTotal: null,
        recipients: []
      }
    };
  },
  validations: {
    details: {
      pattern: {
        required
      },
      /* category: {
        required
      }, */
      dateFrom: {
        required
      },
      timeFrom: {
        required
      },
      description: {
        required
      }
    }
  },
  computed: {
    checkChange() {
      if (this.isEditPage) {
        return _.isEqual(this.details, this.initialData);
      }
      return false;
    },
    markerCoords() {
      const { lat } = this;
      const { lng } = this;
      if (lat && lng) {
        return [lng, lat];
      }

      return null;
    },
    isEditPage() {
      return !!this.$route.params.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  created() {
    this.emptyFormData = this.details;
  },
  mounted() {
    this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;

    if (!this.isEditPage) {
      this.isSave = false;
    }
  },
  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData();

    if (!this.isEditPage) {
      this.isSave = false;
      this.isCheck = true;
    }

    this.isMapReady = true;
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
    this.activeTabId = {
      id: 'information',
      title: 'Информация'
    };
  },
  methods: {
    funcCheck() {
      this.isCheck = !this.isCheck;
      this.isSave = false;
      this.isC;
    },
    getStartDate(date) {
      return formattedDate(Number(date), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
    getFormattedDate(date) {
      return formattedDate(Number(date));
    },
    addPhone() {
      this.details.phones.push({ id: this.details.phones.length + 1, value: this.phone });
      this.phone = null;
      this.isChange('phones');
    },
    async countPeople() {
      this.peopleLoading = true;
      const coordinates = [];
      for (const item of this.details.residentAddressees) {
        coordinates.push([item.lat, item.lng]);
      }
      if (coordinates.length > 0) {
        this.details.peopleTotal = 0;
      } else {
        this.peopleLoading = false;
      }
      for (const item of coordinates) {
        await this.request(item);
      }
      this.peopleLoading = false;
    },
    request(item) {
      return new Promise((resolve, reject) => {
        this.peopleLoading = true;
        this.$store.dispatch('countTotalPeople', {
          token: window.token ? window.token : null,
          coordinates: item
        }).then(res => {
          if (res.error) {
            this.$refs.message.showMessage(res.error, 'error');
            reject();
          } else {
            this.details.peopleTotal += res.peopleTotal;
            resolve(res.peopleTotal);
          }
        });
      });
    },
    onChangePeriod(e) {
      this.selectedPeriod = e.value;
      this.periodList = this.periodList.map(el => ({ ...el, active: el.value === e.value }));
    },
    /* addAddress() {
      this.details.addresses.push({
        id: this.details.addresses.length + 1,
        address: this.searchAddress,
        lat: this.lat,
        lng: this.lng
      });
    }, */
    async onSearchAddress(address, pushArray = false) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.searchAddress;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house && res?.addressFull?.locality) {
          this.searchAddress = `${res.addressFull.street} ${res.addressFull.house}, ${res.addressFull.locality}`;
        } else {
          this.searchAddress = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.lat = res.lat;
        this.lng = res.lng;
      }
      if (pushArray) {
        this.addMap = false;
        this.details.addresses.push(res);
        this.lat = this.lng = this.searchAddress = null;
        this.isChange('addresses');
      } else {
        this.addMap = true;
        this.address = res;
      }
    },
    openResidentAddresseesModal() {
      this.$rir.modal.open(ModalResidentAddressees, {
        saveFunction: this.residentAddresseesSave,
        residentAddressees: this.details.residentAddressees
      });
    },
    residentAddresseesSave(addressees) {
      this.details.residentAddressees = addressees;
    },
    async openContactsModal() {
      /* if (!this.$store.state.allUsers.length) {
        await this.$store.dispatch('getUsers', {
          token: window.token
        });
      } */
      this.$rir.modal.open(ModalContacts, {
        items: this.$store.getters.getActiveUsers || [],
        saveFunction: this.contactsSave,
        emails: this.details.choicestEmails || [],
        phones: this.details.choicestPhones || []
      });
    },
    contactsSave(contacts) {
      this.details.choicestEmails = contacts.choicestEmails;
      this.details.choicestPhones = contacts.choicestPhones;
      this.details.recipients = contacts.recipients;
      this.isChange('recipients');
    },
    addressDelete(id) {
      this.details.addresses = this.details.addresses.filter(item => item.id !== id);
      this.isChange('addresses');
    },
    async onDelete() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: this.$route.params.id,
        title: 'Удалить оперативное оповещение?',
        reloadTo: 'main'
      });
    },
    async setData() {
      this.isLoading = true;
      await this.$store.dispatch('getIncidentsById', this.$route.params.id).then(res => {
        const a = JSON.parse(JSON.stringify(res.all[0]));
        this.details = {
          ...this.details,
          ...delete a.details,
          ...res.all[0].details,
          attachments: res.all[0].details?.attachments ? res.all[0].details.attachments : [],
          dateFrom: Number(res.all[0]?.details?.dateFrom) || null,
          dateTo: Number(res.all[0]?.details?.dateTo) || null,
          peopleTotal: String(res.all[0].subjects != null ? res.all[0].subjects : ''),
          public: Boolean(res.all[0].details?.public)
        };
        this.isCheck = this.details?.public !== 0;
        this.initialData = JSON.parse(JSON.stringify(this.details));
        this.alert = { ...res.all[0], details: { ...this.alert.details, ...res.all[0].details } };
      }).finally(() => {
        if (Object.keys(this.alert).length === 0) {
          this.notFound = true;
        } else {
          this.notFound = false;
        }
        this.isLoading = false;
      });

    },
    stringToCoords(string) {
      return JSON.parse(`[${string}]`);
    },
    onMarkerDragEnd(e) {
      if (!this.$store.getters.userIsRoot) return;
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.lat = coords[0];
        this.lng = coords[1];
        this.onSearchAddress(coords);
      }
    },

    async onMapClick(coords) {
      if (!this.$store.getters.userIsRoot) return;
      if (coords?.length === 2) {
        this.lng = coords[0];
        this.lat = coords[1];
      }

      this.onSearchAddress(coords);
    },

    onChangePattern(val) {
      // this.details.pattern = val.id;
      this.details.patternName = val.title;
      this.details.formName = val.title;
      this.isChange('pattern');
      this.isChange('formName');
    },
    onChangeCategory(val) {
      this.details.categoryData = val;
      this.isChange('category');
    },
    resetData() {
      this.$v.$reset();
      this.details = JSON.parse(JSON.stringify(this.emptyFormData));
    },
    async submit(send) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const dateOptions = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        };

        new Api()
          .sendToIncidents({
            id: this.isEditPage ? this.$route.params.id : -1,
            recipients: this.details.recipients,
            details: this.details,
            ...this.details,
            fio: this.details.fio,
            contacts: this.details.phones,
            // effect: this.details.description,
            address: this.details.addresses,
            dateFrom: formattedDate(Number(this.details.dateFrom), dateOptions),
            dateTo: formattedDate(Number(this.details.dateTo), dateOptions),
            subjects: this.details.peopleTotal,
            incidentType: this.details.category,
            civilAddresses: this.details.residentAddressees,
            formIncidentOption_1: send ? 1 : 0,
            formIncidentOption_2: 1,
            formIncidentOption_3: send ? 1 : 0
          })
          /* new Api().operateIncident({
            id: this.isEditPage ? this.$route.params.id : -1,
            action: 'update',
            item: {
              recipients: this.details.recipients,
              details: this.details,
              ...this.details,
              fio: this.details.fio,
              contacts: this.details.phones,
              // effect: this.details.description,
              address: this.details.addresses,
              public: this.details.public === true ? '1' : '0',
              dateFrom: formattedDate(Number(this.details.dateFrom), dateOptions),
              dateTo: formattedDate(Number(this.details.dateTo), dateOptions),
              subjects: this.details.peopleTotal,
              incidentType: this.details.category,
              civilAddresses: this.details.residentAddressees,
              formIncidentOption_1: send ? 1 : 0,
              formIncidentOption_2: 1,
              formIncidentOption_3: send ? 1 : 0
            }
          }) */
          .then(res => {
            if (res.error) {
              this.$refs.message.showMessage(res.error, 'error');
            } else if (this.isEditPage) {
              window.location.reload();
            } else {
              const r = this.$router.resolve({
                name: 'index'
              });
              window.location.assign(r.href);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    isChange(field) {
      if (!this.isCopy) {
        this.isSave = this.initialData[field] === this.details[field];
      }
    },
    file(files, type) {
      this.details.attachments = [];
      this.formattedFiles(files, type);
    },
    formattedFiles(files, type) {
      let _this = this;
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            contentLength: Number(el.size),
            contentType: el.type,
            filename: el.name,
            icon: null,
            type: 'image/jpeg',
            name: el.name, // .split('.')[0]
            url: `${el.url}`
          }));
          _this.details.attachments.push(...fileList);
        } else {
          _this.details.attachments.push(el);
        }
      });
    },
    uploadFile(files, type) {
      let _this = this;
      if (!files?.length) {
        this.details.attachments = [];
        return;
      }
      _this.formattedFiles(files, type);
    },
  }
};
</script>

<style scoped lang="scss">
.width-max {
  width: max-content !important;
}

.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.rir-input {
      background: rgba(#e14761, 0.08);
    }

    &.rir-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .rir-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .rir-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

::v-deep .rir-modal__content {
  display: block !important;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.rotate-animation {
  animation: spin .8s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
<style>
.rir-date-picker__content {
  min-width: 288px;
  border-radius: 16px;
  padding: 24px;
  height: 244px;
  margin-right: 130px;
  overflow: initial;
}

.rir-date-picker__time {
  position: absolute;
  left: calc(100% + 16px);
  top: 0;
  background: #fff;
  border-radius: 16px;
  padding: 20px 0;
  box-shadow: 0 8px 24px rgba(19, 57, 134, .16);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 112px;
  box-sizing: border-box;
  overflow: hidden
}

.rir-date-picker__time p {
  margin: 0 0 16px 0;
  color: #04153e;
  opacity: .48
}

.rir-date-picker__time .hour {
  margin-left: 16px
}

.rir-date-picker__time .hour, .rir-date-picker__time .minutes {
  padding-right: 12px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  scrollbar-color: rgba(4, 21, 62, .08) transparent
}

.rir-date-picker__time .hour::-webkit-scrollbar-track, .rir-date-picker__time .minutes::-webkit-scrollbar-track {
  width: 4px;
  background: #fff
}

.rir-date-picker__time .hour::-webkit-scrollbar, .rir-date-picker__time .hour::-webkit-scrollbar-thumb, .rir-date-picker__time .minutes::-webkit-scrollbar, .rir-date-picker__time .minutes::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: .72
}

.rir-date-picker__time .hour > span, .rir-date-picker__time .minutes > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: 24px;
  height: 24px;
  color: #113079;
  margin-bottom: 8px;
  border-radius: 4px;
  flex-shrink: 0
}

.rir-date-picker__time .hour > span.active, .rir-date-picker__time .minutes > span.active {
  color: #fff;
  background: #3d75e4
}

.rir-date-picker__time .hour > span:not(.active):hover, .rir-date-picker__time .minutes > span:not(.active):hover {
  background-color: #e4edfb;
  color: #3d75e4
}

.rir-date-picker__time>div {
  display: flex;
  flex-direction: initial;
  width: 100%;
  overflow: auto;
}
</style>
